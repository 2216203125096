import React from 'react';

const SafetyStandards = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.mainTitle}>Child Safety Standards</h1>
        <p style={styles.introText}>
          At Dream – Create & Upload Videos, we prioritize the safety and well-being of our users, especially minors. Our Child Safety Standards are put in place to combat child sexual abuse and exploitation (CSAE) and provide a safe online experience.
        </p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Our Commitment to Child Safety</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>Content Moderation:</strong> We employ a combination of AI-powered tools and manual review processes to identify and remove harmful content associated with CSAE. Our moderation system is active 24/7 to ensure a safe environment.
          </li>
          <li style={styles.listItem}>
            <strong>Age Verification:</strong> To comply with global regulations, we verify the age of all users. Accounts identified as being under the age of 13 are restricted in accordance with COPPA (Children’s Online Privacy Protection Act).
          </li>
          <li style={styles.listItem}>
            <strong>Reporting Mechanism:</strong> We offer an in-app reporting feature that allows users to report safety concerns. All reports are reviewed promptly, and appropriate action is taken based on our safety standards.
          </li>
          <li style={styles.listItem}>
            <strong>Zero Tolerance Policy:</strong> We have a strict zero-tolerance policy towards any CSAM (Child Sexual Abuse Material). Any violations lead to immediate suspension and permanent removal from our platform, with cooperation from relevant authorities.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>How to Report Child Safety Concerns</h2>
        <p style={styles.paragraph}>
          If you encounter any content that violates our child safety policies or suspect any CSAE activity, please use our in-app reporting system or contact us directly at:
        </p>
        <p style={styles.contact}>
          <strong>Email:</strong> <a href="mailto:dream.com.intenet@gmail.com" style={styles.emailLink}>dream.com.intenet@gmail.com</a>
        </p>
        <p style={styles.paragraph}>
          All reports are reviewed immediately, and actions are taken in accordance with our commitment to child safety.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Contact Information</h2>
        <p style={styles.paragraph}>
          For further inquiries regarding our child safety practices, please reach out to us at the following email address:
        </p>
        <p style={styles.contact}>
          <strong>Email:</strong> <a href="mailto:dream.com.intenet@gmail.com" style={styles.emailLink}>dream.com.intenet@gmail.com</a>
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Conclusion</h2>
        <p style={styles.paragraph}>
          Our goal is to ensure that Dream – Create & Upload Videos remains a safe and enjoyable platform for all users. By adhering to these child safety standards, we contribute to the protection of children online and foster a responsible digital community.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Terms and Policies</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}><a href="https://newspakupdat.blogspot.com/p/privacy-policy-of-dream-application.html" style={styles.link}>Privacy Policy</a></li>
        </ul>
      </section>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    margin: '40px auto',
    padding: '0 20px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    maxWidth: '1000px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    lineHeight: '1.8',
  },
  header: {
    textAlign: 'center',
    paddingBottom: '30px',
    borderBottom: '3px solid #ddd',
    marginBottom: '30px',
  },
  mainTitle: {
    color: '#2C3E50',
    fontSize: '36px',
    fontWeight: '700',
    marginBottom: '15px',
  },
  introText: {
    color: '#7F8C8D',
    fontSize: '18px',
    maxWidth: '90%',
    margin: '0 auto',
  },
  section: {
    marginBottom: '40px',
    padding: '0 10px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#2980B9',
    marginBottom: '15px',
    borderBottom: '2px solid #2980B9',
    paddingBottom: '10px',
  },
  list: {
    paddingLeft: '20px',
    listStyleType: 'disc',
    fontSize: '18px',
    color: '#34495E',
  },
  listItem: {
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '18px',
    color: '#34495E',
    lineHeight: '1.6',
    marginBottom: '15px',
  },
  contact: {
    fontSize: '18px',
    color: '#2980B9',
  },
  emailLink: {
    textDecoration: 'none',
    color: '#2980B9',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: '#2980B9',
    fontWeight: '500',
  },
};

export default SafetyStandards;
